import { useParams } from 'react-router-dom';

import { SiteType, SiteView } from '../types';
import { getRole } from "../utils/auth";

const DEFAULT_ADS_SITE_VIEW: SiteView = 'production';
const DEFAULT_TFM_SITE_VIEW: SiteView = 'traffic_flow';
const DEFAULT_COMMON_SITE_VIEW: SiteView = 'audit';

const ADS_SITE_VIEWS: SiteView[] = ['production', 'archive', 'recordings'];
const TFM_SITE_VIEWS: SiteView[] = ['traffic_flow', 'traffic_flow_archive', 'comparison'];
const COMMON_SITE_VIEWS: SiteView[] = ['audit', 'setup'];


const getDefaultSiteView: (siteType: SiteType) => SiteView = (siteType) => {
  switch (siteType) {
    case 'ADS':
      return DEFAULT_ADS_SITE_VIEW;
    case 'TFM':
      return DEFAULT_TFM_SITE_VIEW;
    default:
      return DEFAULT_COMMON_SITE_VIEW;
  }
}

const useSiteView: (siteType: SiteType) => SiteView = (siteType) => {
  let { siteView } = useParams();
  let view: SiteView = siteView as SiteView;

  if (!view) {
    view = getDefaultSiteView(siteType);
  }

  if (
    !COMMON_SITE_VIEWS.includes(view) && (
      ('ADS' === siteType && !ADS_SITE_VIEWS.includes(view)) ||
      ('TFM' === siteType && !TFM_SITE_VIEWS.includes(view))
    )
  ) {
    view = getDefaultSiteView(siteType);
  }
  if (getRole() === "user" && view === "setup") {
    view = getDefaultSiteView(siteType);
  }
  return view;
};

export default useSiteView;
