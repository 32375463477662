import React from 'react';

import Box from '@mui/material/Box';

import VideoPlayer from './VideoPlayer';
import VerifyDetection from './VerifyDetection';
import ToggleVehicleStats from './ToggleVehicleStats';
import KeyFrameViewer from "./KeyFrameViewer";

import { Detection, EdgeProcessConfig } from '../types';
import { ASSET_URL } from '../config';
import { formatDate } from '../utils';

interface Props {
  detection: Detection;
  processConfig?: EdgeProcessConfig;
  onVerified?: (d: Detection) => void;
}

const InlineDetection: React.FC<Props> = ({ detection, processConfig, onVerified }) => {
  const getDownloadFileName: (time: number) => string = (time) => {
    return (detection.vehicle_number + '-' + formatDate(detection.start_time).replace(' ', '-') + '-' + time).replace(/ /g, '').replace(/:/g, '').replace(/\./g, '') + '.jpg';
  };

  const captureVideo = processConfig?.capture_video ?? true;

  return (
    <Box sx={{ display: 'flex' }} style={{ maxWidth: "1500px" }} gap={2}>
      <Box sx={{ flex: '1 1 0', marginBottom: "-15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
        {captureVideo ?
          <VideoPlayer
            url={`${ASSET_URL}/${detection.process_id}/snippet-${detection.vehicle_number}.mp4`}
            rawUrl={`${ASSET_URL}/${detection.process_id}/raw-snippet-${detection.vehicle_number}.mp4`}
            trainingUrl={!!detection.has_training_images ? `${ASSET_URL}/${detection.process_id}/training-${detection.vehicle_number}.tgz` : undefined}
            fps={30}
            frame={0}
            getDownloadFileName={getDownloadFileName}
          /> : <KeyFrameViewer detection={detection} />}
          {/*<img alt={`Vehicle ${detection.vehicle_number}`} src={`${ASSET_URL}/${detection.process_id}/thumbnail-${detection.vehicle_number}.png`} style={{ maxWidth: 640, maxHeight: 360 }} /> */}
      </Box>
      <Box sx={{ flex: '1 1 0' }}>
        <VerifyDetection detection={detection} onVerified={onVerified} />
      </Box>
      <Box sx={{ flex: '1 1 0' }}>
        <ToggleVehicleStats detection={detection} />
      </Box>
    </Box>
  );
};

export default InlineDetection;
