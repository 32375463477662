import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import Download from "@mui/icons-material/Download";
import NextFrame from "@mui/icons-material/FastForward";
import PrevFrame from "@mui/icons-material/FastRewind";

import { Detection } from "../types";
import { ASSET_URL } from "../config";

interface Props {
  detection: Detection;
  maxWidth?: number;
  maxHeight?: number;
}

const DEFAULT_MAX_WIDTH = 640;
const DEFAULT_MAX_HEIGHT = 360;

const KeyFrameViewer: React.FC<Props> = ({
  detection,
  maxWidth = DEFAULT_MAX_WIDTH,
  maxHeight= DEFAULT_MAX_HEIGHT,
}) => {
  const [axleIndex, setAxleIndex] = useState(0);

  const imgSrc = `${ASSET_URL}/${detection.process_id}/key-frame-${detection.vehicle_number}-${axleIndex}.jpg`;

  const handleClickDownloadTraining = () => {
    if (detection.has_training_images) {
      const trainingUrl = `${ASSET_URL}/${detection.process_id}/raw-key-frame-${detection.vehicle_number}-${axleIndex}.jpg`;
      const trainingFileName = `training-${detection.vehicle_number}-${axleIndex}.jpg`;
      const a = document.createElement('a');
      a.setAttribute('href', trainingUrl);
      a.setAttribute('download', trainingFileName);
      a.click();
    }
  };

  const handleClickNext = () => {
    setAxleIndex(s => (s + 1) % detection.num_tires);
  };

  const handleClickPrevious = () => {
    setAxleIndex(s => (s + detection.num_tires - 1) % detection.num_tires);
  };

  return (
    <div style={{ maxWidth }}>
      <img alt={`Axle ${axleIndex + 1}`} src={imgSrc} style={{ maxWidth, maxHeight }} />
      <IconButton onClick={handleClickPrevious}><PrevFrame /></IconButton>
      <span>Axle {axleIndex +1}</span>
      <IconButton onClick={handleClickNext}><NextFrame /></IconButton>
      {detection.has_training_images && (
        <IconButton
          style={{ marginRight: '1rem', color: '#1976d2'}}
          onClick={handleClickDownloadTraining}
        >
          <Download />
        </IconButton>
      )}
    </div>
  );
};

export default KeyFrameViewer;
