import { BACKEND_URL } from '../config';

import {
  Model,
  ModelCreate,
  Tenant,
  TenantCreate,
  Site,
  SiteCreate,
  SiteEdit,
  SoftwareVersion,
  SoftwareVersionCreate,
  User,
  UserCreate,
  Lane,
  LaneCreate,
  Camera,
  CameraCreate,
  CameraEdit,
  Processor,
  ProcessorCreate,
  Process,
  ProcessCreate,
  FullProcessCreate,
  ProductionProcessCreate,
  CameraConfig,
  CameraConfigCreate,
  CameraModel,
  ProcessConfig,
  ProcessWithConfig,
  ProcessConfigCreate,
  ProductionDetections,
  Detection,
  DetectionSummary,
  DetectionDetail,
  DetectionVerify,
  DetectionHistogram,
  View,
  ViewDetection,
  ViewStats,
  ObservationUpload,
  Comparison,
  ComparisonCreate,
  Observation,
  DetectionObservation,
  TrafficFlow,
  TrafficFlowSummary,
  ViewConfig,
  ViewType,
  EdgeProcessConfig
} from '../types';

const getBaseHeaders = () => new Headers({
  'Authorization': 'Bearer ' + localStorage.getItem('token')
});

type ApiMethod = 'GET' | 'PUT' | 'POST' | 'DELETE';

export class APIError extends Error {
  details: any;
  constructor(details: any) {
    super('API error')
    this.details = details;
  }
}

export const callApi = async (url: string, method: ApiMethod = 'GET', body?: string) => {
  const headers = getBaseHeaders();
  if (body) {
    headers.append('Content-Type', 'application/json');
  }
  const response = await fetch(BACKEND_URL + url, { method, body, headers });
  if (!response.ok) {
    if (response.status === 401) {
      window.location.href = '/login';
      return;
    }
    const details = await response.json();
    throw new APIError(details);
  }
  return response.json();
};

const upload = async (url: string, file: File) => {
  const headers = getBaseHeaders();
  const body = new FormData();
  body.append('upload_file', file);
  const response = await fetch(BACKEND_URL + url, { method: 'POST', headers, body });
  if (!response.ok) {
    const details = await response.json() as any;
    throw new Error(details.detail);
  }
  return response.json();
};

export const getUsers: () => Promise<User[]> = async () => callApi('/users');

export const getUser: (user_id: number) => Promise<User> = async (user_id: number) => callApi('/users/' + user_id);

export const createUser: (user: UserCreate) => Promise<User> = async (user: UserCreate) => callApi('/users', 'POST', JSON.stringify(user));

export const updateUser: (user: User) => Promise<User> = async (user: User) => callApi('/users/' + user.id, 'PUT', JSON.stringify({ name: user.name, role: user.role }));

export const updateViewConfig: (view_config: ViewConfig) => Promise<ViewConfig> = async (view_config: ViewConfig) => callApi('/view-configs/' + view_config.id, 'PUT', JSON.stringify({ process_id: view_config.process_id }));

export const deleteUser: (user_id: number) => void = async (user_id: number) => callApi('/users/' + user_id, 'DELETE');

export const getTenants: () => Promise<Tenant[]> = async () => callApi('/tenants');

export const createTenant: (tenant: TenantCreate) => Promise<Tenant> = async (tenant: TenantCreate) => callApi('/tenants', 'POST', JSON.stringify(tenant));

export const deleteTenant: (tenant_id: number) => void = async (tenant_id: number) => callApi('/tenants/' + tenant_id, 'DELETE');

export const getModels: () => Promise<Model[]> = async () => callApi('/models');

export const createModel: (model: ModelCreate) => Promise<Model> = async (model: ModelCreate) => callApi('/models', 'POST', JSON.stringify(model));

export const deleteModel: (model_id: number) => void = async (model_id: number) => callApi('/models/' + model_id, 'DELETE');

export const getSoftwareVersions: () => Promise<SoftwareVersion[]> = async () => callApi('/software-versions');

export const createSoftwareVersion: (version: SoftwareVersionCreate) => Promise<SoftwareVersion> = async (version: SoftwareVersionCreate) => callApi('/software-versions', 'POST', JSON.stringify(version));

export const getSites: () => Promise<Site[]> = async () => callApi('/sites');

export const getSite: (site_id: number) => Promise<Site> = async (site_id: number) => callApi('/sites/' + site_id);

export const createSite: (site: SiteCreate) => Promise<Site> = async (site: SiteCreate) => callApi('/sites', 'POST', JSON.stringify(site));

export const deleteSite: (site_id: number) => Promise<void> = async (site_id: number) => callApi('/sites/' + site_id, 'DELETE');

export const editSite: (site_id: number, site: SiteEdit) => Promise<Site> = async (site_id: number, site: SiteEdit) => callApi('/sites/' + site_id, 'PUT', JSON.stringify(site));

export const getLanes: () => Promise<Lane[]> = async () => callApi('/lanes');

export const getLanesBySite: (site_id: number) => Promise<Lane[]> = async (site_id: number) => callApi('/sites/' + site_id + '/lanes');

export const createLane: (lane: LaneCreate) => Promise<Lane> = async (lane: LaneCreate) => callApi('/lanes', 'POST', JSON.stringify(lane));

export const getCameras: () => Promise<Camera[]> = async () => callApi('/cameras');

export const getCamera: (camera_id: number) => Promise<Camera> = async (camera_id: number) => callApi('/cameras/' + camera_id);

export const getCamerasBySite: (site_id: number) => Promise<Camera[]> = async (site_id: number) => callApi('/sites/' + site_id + '/cameras');

export const createCamera: (camera: CameraCreate) => Promise<Camera> = async (camera: CameraCreate) => callApi('/cameras', 'POST', JSON.stringify(camera));

export const updateCamera: (camera_id: number, camera: CameraEdit) => Promise<Camera> = async (camera_id: number, camera: CameraEdit) => callApi('/cameras/' + camera_id, 'PUT', JSON.stringify(camera));

export const getProcessors: () => Promise<Processor[]> = async () => callApi('/processors');

export const getProcessorsBySite: (site_id: number) => Promise<Processor[]> = async (site_id: number) => callApi('/sites/' + site_id + '/processors');

export const createProcessor: (processor: ProcessorCreate) => Promise<Processor> = async (processor: ProcessorCreate) => callApi('/processors', 'POST', JSON.stringify(processor));

export const deleteProcessor: (processor_id: number) => Promise<void> = async (processor_id: number) => callApi('/processors/' + processor_id, 'DELETE');

export const getProcesses: () => Promise<Process[]> = async () => callApi('/processes');

export const getProcess: (process_id: number) => Promise<Process> = async (process_id: number) => callApi('/processes/' + process_id);

export const getProcessesBySite: (site_id: number) => Promise<Process[]> = async (site_id) => callApi('/sites/' + site_id + '/processes');

export const createProcess: (process: ProcessCreate) => Promise<Process> = async (process: ProcessCreate) => callApi('/processes', 'POST', JSON.stringify(process));

export const deleteProcess: (process_id: number) => Promise<void> = async (process_id: number) => callApi('/processes/' + process_id, 'DELETE');

export const createFullProcess: (process: FullProcessCreate) => Promise<Process> = async (process: FullProcessCreate) => callApi('/processes/full', 'POST', JSON.stringify(process));

export const createProductionProcess: (process: ProductionProcessCreate) => Promise<Process> = async (process: ProductionProcessCreate) => callApi('/processes/production', 'POST', JSON.stringify(process));

export const getCameraConfigs: () => Promise<CameraConfig[]> = async () => callApi('/camera-configs');

export const createCameraConfig: (config: CameraConfigCreate) => Promise<CameraConfig> = async (config: CameraConfigCreate) => callApi('/camera-configs', 'POST', JSON.stringify(config));

export const getCameraModels: () => Promise<CameraModel[]> = async () => callApi('/camera-models');

export const getProcessConfigs: () => Promise<ProcessConfig[]> = async () => callApi('/process-configs');

export const createProcessConfig: (config: ProcessConfigCreate) => Promise<ProcessConfig> = async (config: ProcessConfigCreate) => callApi('/process-configs', 'POST', JSON.stringify(config));

export const parseDetection: (detection: Detection) => Detection = (detection) => ({ ...detection, start_time: new Date(detection.start_time), end_time: new Date(detection.end_time) });

export const getDetections: (process_id: number, start_time?: string, end_time?: string, limit?: number, offset?: number) => Promise<Detection[]> = async (process_id: number, start_time?: string, end_time?: string, limit: number = 100, offset: number = 0) => {
  let url = '/processes/' + process_id + '/detections?limit=' + limit + '&offset=' + offset;
  if (start_time) {
    const start = new Date(start_time);
    const startstr = start.getFullYear() + '-' + ('0' + (start.getMonth() + 1)).substr(-2) + '-' + ('0' + start.getDate()).substr(-2) + ' ' + ('0' + start.getHours()).substr(-2) + ':' + ('0' + start.getMinutes()).substr(-2) + ':00';
    url += '&start_stamp=' + encodeURIComponent(startstr);
  }
  if (end_time) {
    const end = new Date(end_time);
    const endstr = end.getFullYear() + '-' + ('0' + (end.getMonth() + 1)).substr(-2) + '-' + ('0' + end.getDate()).substr(-2) + ' ' + ('0' + end.getHours()).substr(-2) + ':' + ('0' + end.getMinutes()).substr(-2) + ':00';
    url += '&end_stamp=' + encodeURIComponent(endstr);
  }
  return callApi(url).then(result => result.map(parseDetection));
};

export const getDetectionsByView: (
  view_id: number,
  start_time?: string,
  end_time?: string,
  vehicle_class?: string,
  vehicle_subclass? : string,
  min_axles?: number,
  max_axles?: number,
  min_raised_axles?: number,
  min_raised_axles_ml?: number,
  raised_axle_mismatch?: boolean,
  minimum_vehicle_score?: string,
  minimum_tire_score?: string,
  vehicle_minimum_mode?: string,
  axle_minimum_mode?: string,
  vehicle_lowest_maximum?: string,
  axle_lowest_maximum?: string,
  limit?: number,
  offset?: number,
  sort_column?: string,
  sort_direction?: string
) => Promise<Detection[]> = async (
  view_id: number,
  start_time?: string,
  end_time?: string,
  vehicle_class?: string,
  vehicle_subclass? : string,
  min_axles?: number, 
  max_axles?: number, 
  min_raised_axles?: number,
  min_raised_axles_ml?: number,
  raised_axle_mismatch?: boolean,
  minimum_vehicle_score?: string,
  minimum_tire_score?: string,
  vehicle_minimum_mode?: string,
  axle_minimum_mode?: string,
  vehicle_lowest_maximum?: string,
  axle_lowest_maximum?: string,
  limit?: number,
  offset?: number,
  sort_column: string = 'start_time',
  sort_direction: string = 'desc'
) => {
  let prefix = '?';
  let url = '/views/' + view_id + '/detections';
  if (limit) {
    url += prefix + 'limit=' + limit;
    prefix = '&';
  }
  if (offset) {
    url += prefix + 'offset=' + offset;
    prefix = '&';
  }
  if (start_time) {
    const start = new Date(start_time);
    const startstr = start.getFullYear() + '-' + ('0' + (start.getMonth() + 1)).substr(-2) + '-' + ('0' + start.getDate()).substr(-2) + ' ' + ('0' + start.getHours()).substr(-2) + ':' + ('0' + start.getMinutes()).substr(-2) + ':00';
    url += prefix + 'start_stamp=' + encodeURIComponent(startstr);
    prefix = '&';
  }
  if (end_time) {
    const end = new Date(end_time);
    const endstr = end.getFullYear() + '-' + ('0' + (end.getMonth() + 1)).substr(-2) + '-' + ('0' + end.getDate()).substr(-2) + ' ' + ('0' + end.getHours()).substr(-2) + ':' + ('0' + end.getMinutes()).substr(-2) + ':00';
    url += prefix + 'end_stamp=' + encodeURIComponent(endstr);
    prefix = '&';
  }
  if (vehicle_class) {
    url += prefix + 'vehicle_class=' + vehicle_class;
    prefix = '&';
  }
  if (vehicle_subclass) {
    url += prefix + 'vehicle_subclass=' + vehicle_subclass;
    prefix = '&';
  }
  if (typeof min_axles !== 'undefined') {
    url += prefix + 'min_axles=' + min_axles;
    prefix = '&';
  }
  if (typeof max_axles !== 'undefined') {
    url += prefix + 'max_axles=' + min_axles;
    prefix = '&';
  }
  if (min_raised_axles) {
    url += prefix + 'min_raised_axles=' + min_raised_axles;
    prefix = '&';
  }
  if (min_raised_axles_ml) {
    url += prefix + 'min_raised_axles_ml=' + min_raised_axles_ml;
    prefix = '&';
  }
  if (raised_axle_mismatch) {
    url += prefix + 'raised_axle_mismatch=' + raised_axle_mismatch;
    prefix = '&';
  }
  if (minimum_vehicle_score) {
    url += prefix + 'minimum_vehicle_score=' + minimum_vehicle_score;
    prefix = '&';
  }
  if (minimum_tire_score) {
    url += prefix + 'minimum_tire_score=' + minimum_tire_score;
    prefix = '&';
  }
  if (vehicle_minimum_mode) {
    url += prefix + 'vehicle_minimum_mode=' + vehicle_minimum_mode;
    prefix = '&';
  }
  if (axle_minimum_mode) {
    url += prefix + 'axle_minimum_mode=' + axle_minimum_mode;
    prefix = '&';
  }
  if (vehicle_lowest_maximum) {
    url += prefix + 'vehicle_lowest_maximum=' + vehicle_lowest_maximum;
    prefix = '&';
  }
  if (axle_lowest_maximum) {
    url += prefix + 'axle_lowest_maximum=' + axle_lowest_maximum;
    prefix = '&';
  }
  if (sort_column) {
    url += prefix + 'sort_column=' + sort_column + '&sort_order=' + sort_direction;
    prefix = '&';
  }
  return callApi(url).then(result => result.map(parseDetection));
};

export const getProductionDetectionsByView: (
  view_id: number
) => Promise<ProductionDetections> = (
  view_id: number
) => {
  const url = `/views/${view_id}/detections/production`;
  return callApi(url).then(result => ({
    lane_id: result.lane_id,
    processor_id: result.processor_id,
    heartbeat_at: result.heartbeat_at ? new Date(result.heartbeat_at) : undefined,
    servertime_at: result.servertime_at ? new Date(result.servertime_at) : undefined,
    detections: result.detections.map(parseDetection)
  }));
};

export const getProductionDetectionsByLane: (
  lane_id: number
) => Promise<ProductionDetections> = (
  lane_id: number
) => {
  const url = `/lane/${lane_id}/detections/production`;
  return callApi(url).then(result => ({
    lane_id: result.lane_id,
    processor_id: result.processor_id,
    heartbeat_at: result.heartbeat_at ? new Date(result.heartbeat_at) : undefined,
    servertime_at: result.servertime_at ? new Date(result.servertime_at) : undefined,
    detections: result.detections.map(parseDetection)
  }));
};

export const getDetectionSummaryByView: (
  view_id: number,
  start_time?: string,
  end_time?: string
) => Promise<DetectionSummary[]> = (
  view_id: number,
  start_time?: string,
  end_time?: string
) => {
  if (-1 === view_id) {
    return Promise.resolve([] as DetectionSummary[]);
  }
  let url = '/views/' + view_id + '/detections/summary';
  let prefix = '?';
  if (start_time) {
    const start = new Date(start_time);
    const startstr = start.getFullYear() + '-' + ('0' + (start.getMonth() + 1)).substr(-2) + '-' + ('0' + start.getDate()).substr(-2) + ' ' + ('0' + start.getHours()).substr(-2) + ':' + ('0' + start.getMinutes()).substr(-2) + ':00';
    url += prefix + 'start_stamp=' + encodeURIComponent(startstr);
    prefix = '&';
  }
  if (end_time) {
    const end = new Date(end_time);
    const endstr = end.getFullYear() + '-' + ('0' + (end.getMonth() + 1)).substr(-2) + '-' + ('0' + end.getDate()).substr(-2) + ' ' + ('0' + end.getHours()).substr(-2) + ':' + ('0' + end.getMinutes()).substr(-2) + ':00';
    url += prefix + 'end_stamp=' + encodeURIComponent(endstr);
    prefix = '&';
  }
  return callApi(url);
};

const dateToString: (date: Date) => string = (date: Date) => {
  return date.getFullYear() + '-' + ('0' + (date.getMonth() + 1)).substr(-2) + '-' + ('0' + date.getDate()).substr(-2) + ' ' + ('0' + date.getHours()).substr(-2) + ':' + ('0' + date.getMinutes()).substr(-2) + ':' + ('0' + date.getSeconds()).substr(-2) + '.' + date.getMilliseconds();
}

export const getRecentDetectionsByView: (view_id: number, since: Date, until?: Date) => Promise<Detection[]> = async (view_id: number, since: Date, until?: Date) => {
  let url = `/views/${view_id}/recent_detections?since=${dateToString(since)}`;
  if (until) {
    url += '&until=' + dateToString(until);
  }
  return callApi(url).then(result => result.map(parseDetection));
}

const parseDetectionDetail: (detection: DetectionDetail) => DetectionDetail = (detection) => ({ ...detection, start_time: new Date(detection.start_time), end_time: new Date(detection.end_time) });

export const getDetection: (detection_id: number) => Promise<DetectionDetail> = async (detection_id: number) => callApi('/detections/' + detection_id).then(result => parseDetectionDetail(result));

export const getDetectionByViewAndVehicle: (view_id: number, vehicle_number: number) => Promise<DetectionDetail> = async (view_id: number, vehicle_number: number) => callApi('/views/' + view_id + '/detections/' + vehicle_number).then(result => parseDetectionDetail(result));

const parseViewStats: (stats: ViewStats) => ViewStats = stats => ({
  ...stats,
  longest: stats.longest ? parseDetection(stats.longest) : undefined,
  shortest: stats.shortest ? parseDetection(stats.shortest) : undefined,
});
const parseViewDetection: (view: ViewDetection) => ViewDetection = (view) => ({
  ...view,
  detections: view.detections.map(parseDetection),
  longest_detection: view.longest_detection ? parseDetection(view.longest_detection) : undefined,
  stats: view.stats ? parseViewStats(view.stats) : undefined
});

export const updateDetectionVerification: (detection: DetectionVerify) => Promise<Detection> = async (detection: DetectionVerify) => callApi('/detections/' + detection.id, 'PUT', JSON.stringify(detection)).then(result => parseDetection(result));

export const getLiveDetections: (site_id: number) => Promise<ViewDetection[]> = async (site_id: number) => {
  return callApi('/sites/' + site_id + '/live_detections').then(result => result.map(parseViewDetection));
}

const parseDetectionHistogram: (detection: DetectionHistogram) => DetectionHistogram = (detection) => ({ ...detection, start_time: new Date(detection.start_time), end_time: new Date(detection.end_time) });

export const getDetectionHistogram: (view_id: number, start_time: Date, interval: number, duration: number) => Promise<DetectionHistogram[]> = async (view_id: number, start_time: Date, interval: number, duration: number) => {
  const startstr = start_time.getFullYear() + '-' + ('0' + (start_time.getMonth() + 1)).substr(-2) + '-' + ('0' + start_time.getDate()).substr(-2) + ' ' + ('0' + start_time.getHours()).substr(-2) + ':' + ('0' + start_time.getMinutes()).substr(-2) + ':00';
  let endstr = '';
  if (duration) {
    const end_time = new Date(start_time.valueOf() + duration * 3600 * 1000);
    endstr = '&end_time=' + end_time.getFullYear() + '-' + ('0' + (end_time.getMonth() + 1)).substr(-2) + '-' + ('0' + end_time.getDate()).substr(-2) + ' ' + ('0' + end_time.getHours()).substr(-2) + ':' + ('0' + end_time.getMinutes()).substr(-2) + ':00';
  }
  const url = '/views/' + view_id + '/histogram?start_time=' + encodeURIComponent(startstr) + '&interval=' + interval + endstr;
  return callApi(url).then(result => result.map(parseDetectionHistogram));
};

export const getView: (view_id: number) => Promise<View> = async (view_id: number) => callApi('/views/' + view_id);

export const getViews: (site_id: number, view_type: ViewType) => Promise<View[]> = async (site_id: number, view_type: ViewType) => callApi('/sites/' + site_id + `/views?view_type=${view_type}`);

export const getTrafficFlows: (site_id: number, end_time?: string) => Promise<TrafficFlowSummary[]> = async (site_id: number) => callApi(`/sites/${site_id}/traffic_flows`);

export const getTrafficFlow: (traffic_flow_id: number, end_time?: string) => Promise<TrafficFlow> = async (traffic_flow_id: number, end_time?: string) => {
  let url = '/traffic_flows/' + traffic_flow_id;
  if (end_time) {
    const end = new Date(end_time);
    const endstr = end.getFullYear() + '-' + ('0' + (end.getMonth() + 1)).substr(-2) + '-' + ('0' + end.getDate()).substr(-2) + ' ' + ('0' + end.getHours()).substr(-2) + ':' + ('0' + end.getMinutes()).substr(-2) + ':00';
    url += '?end_stamp=' + encodeURIComponent(endstr);
  }
  return callApi(url).then(traffic_flow => ({ ...traffic_flow, entrance: parseViewDetection(traffic_flow.entrance), exit: parseViewDetection(traffic_flow.exit) }));
}

export const uploadObservations: (site_id: number, file: File) => Promise<ObservationUpload> = async (site_id: number, file: File) => upload('/sites/' + site_id + '/observations', file);

export const getUploads: (site_id: number) => Promise<ObservationUpload[]> = async (site_id: number) => callApi('/sites/' + site_id + '/observations');

const parseObservation: (observation: Observation) => Observation = (observation) => ({ ...observation, date_time: new Date(observation.date_time) });

export const parseComparison: (comparison: Comparison) => Comparison = (comparison) => ({ ...comparison, matches: comparison.matches.map(m => ({ detection: m.detection ? parseDetection(m.detection) : undefined, observation: m.observation ? parseObservation(m.observation) : undefined })) });
export const createComparison: (comparison: ComparisonCreate) => Promise<Comparison> = async (comparison: ComparisonCreate) => callApi('/comparisons', 'POST', JSON.stringify(comparison)).then(result => parseComparison(result));

export const getComparison: (comparison_id: number) => Promise<Comparison> = async (comparison_id: number) => callApi('/comparisons/' + comparison_id);

export const associateObservationDetection: (observation_id: number, detection_id: number) => Promise<DetectionObservation> = async (observation_id, detection_id) => callApi(`/observations/${observation_id}/detection`, 'POST', JSON.stringify({ detection_id })).then(result => ({ observation: parseObservation(result.observation), detection: parseDetection(result.detection) }));

export const disassociateObservationDetection: (observation_id: number) => Promise<DetectionObservation[]> = async (observation_id) => callApi(`/observations/${observation_id}/detection`, 'DELETE').then((result: DetectionObservation[]) => result.map((m: DetectionObservation) => ({ observation: m.observation ? parseObservation(m.observation) : undefined, detection: m.detection ? parseDetection(m.detection) : undefined })));

export const getViewConfigBySite: (site_id: number, view_type: ViewType) => Promise<ViewConfig[]> = async (site_id, view_type) => callApi(`/view-configs/${site_id}?view_type=${view_type}`);

export const getProcessConfigsBySite: (site_id: number) => Promise<EdgeProcessConfig[]> = async (site_id) => callApi(`/site-configs/${site_id}`);

export const getProcessesWithConfigBySite: (site_id: number) => Promise<ProcessWithConfig[]> = async (site_id) => callApi(`/sites/${site_id}/processes`);
