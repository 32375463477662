import React from 'react';
import { useNavigate } from 'react-router-dom';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { SiteType, SiteView } from '../types';
import { getRole } from "../utils/auth";

interface Props {
  siteId: number;
  siteType: SiteType;
  siteView: SiteView;
}

const SiteViewToggle: React.FC<Props> = ({ siteId, siteType, siteView }) => {
  const navigate = useNavigate();
  const onChange = (_: React.MouseEvent<HTMLElement>, newView: SiteView) => {
    navigate(`/sites/${siteId}/${newView}`);
  };
  
  return (
    <ToggleButtonGroup
      value={siteView}
      exclusive
      onChange={onChange}
      sx={{
        ml: '1rem',
        mr: '1rem',
        backgroundColor: 'white'
      }}
    >
      {siteType === 'TFM' && <ToggleButton value="traffic_flow">Traffic</ToggleButton>}  
      {siteType === 'TFM' && <ToggleButton value="traffic_flow_archive">Archive</ToggleButton>}  
      {siteType === 'TFM' && <ToggleButton value="comparison">Compare</ToggleButton>}  
      {/*siteType === 'ADS' && <ToggleButton value="live">Live</ToggleButton>*/}  
      {siteType === 'ADS' && <ToggleButton value="production">Production</ToggleButton>}  
      {siteType === 'ADS' && <ToggleButton value="archive">Archive</ToggleButton>}  
      {siteType === 'ADS' && <ToggleButton value="recordings">Recordings</ToggleButton>}  
      <ToggleButton value="audit">Audit</ToggleButton>
      {getRole() !== "user" && <ToggleButton value="setup">Setup</ToggleButton>}
    </ToggleButtonGroup>
  );
};

export default SiteViewToggle;
