import React, { useState } from 'react';
import { useQuery, useMutation, useQueryClient, UseMutationResult } from 'react-query';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';

import CircularProgress from '@mui/material/CircularProgress';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';

import { ViewConfig,  ViewType } from '../types';
import { getViewConfigBySite, updateViewConfig } from '../utils/api';
import ProcessSelect from '../components/ProcessSelect';

interface ViewConfigsProps {
	siteId: number;
  view_type: ViewType;
}

interface ViewConfigRowProps {
  siteId: number;
  view: ViewConfig;
  editViewConfig: UseMutationResult<ViewConfig, unknown, ViewConfig>;
}

const ViewConfigRow: React.FC<ViewConfigRowProps> = ({ siteId, view, editViewConfig }) => {
  const [editing, setEditing] = useState<boolean>(false);
  const [processId, setProcessId] = useState<number | undefined>(view.process_id);

  const handleChangeProcessId = (event: React.ChangeEvent<HTMLInputElement>) => setProcessId(+event.target.value);

  const handleSave = () => {
    editViewConfig.mutate({
      ...view,
      process_id: processId === -1 ? undefined : processId
    });
    setEditing(false);
  }

  const toggleEditing = () => setEditing(old => !old);
  return (
    <TableBody>
      <TableRow>
        <TableCell>{view.id}</TableCell>
        <TableCell>{view.name}</TableCell>
        <TableCell>{view.site_name}</TableCell>
        <TableCell>{view.camera_name}</TableCell>
        <TableCell>{view.lane_name}</TableCell>
        <TableCell>{view.processor_name}</TableCell>
        <TableCell>{view.model_name}</TableCell>
        <TableCell>
          <ProcessSelect label="" siteId={siteId} cameraId={view.camera_id} disabled={!editing} value={processId} onChange={handleChangeProcessId} includeNone={true} />
        </TableCell>
        {'live' === view.view_type && <TableCell>
          {editing ?
            <Button onClick={handleSave}>Save</Button> :
            <Button onClick={toggleEditing}>Edit</Button>}
        </TableCell>}
     </TableRow>
    {'live' === view.view_type && view.config && <TableRow key="1">
      <TableCell colSpan={3}>
        Direction: {view.config.direction}<br />
        Vehicle Score Cutoff: {view.config.vehicle_score_cutoff}<br />
        Axle Score Cutoff: {view.config.tire_score_cutoff}<br />
        Raised Axle Score Cutoff: {view.config.raised_axle_score_cutoff}<br />
        Vehicle Lookback Frames: {view.config.vehicle_lookback_frames}<br />
        Axle Lookback Frames: {view.config.tire_lookback_frames}<br />
      </TableCell>
      <TableCell colSpan={2}>
        Top Border: {view.config.border_cutoff_top}<br />
        Bottom Border: {view.config.border_cutoff_bottom}<br />
        Left Border: {view.config.border_cutoff_left}<br />
        Right Border: {view.config.border_cutoff_right}<br />
        Straddle Left: {view.config.direction === 'RTL' ? view.config.straddle_cutoff_lower : view.config.straddle_cutoff_upper}<br />
        Straddle Right: {view.config.direction === 'LTR' ? view.config.straddle_cutoff_lower : view.config.straddle_cutoff_upper}<br />
      </TableCell>
      <TableCell colSpan={2}>
        {view.config.width_coefficients && <><span>Width Calculation:<br />{view.config.width_coefficients.reverse().map((x, i) => !x ? false : `${x} ${i > 0 ? `* x^${i}` : ''}`).filter(x => x).reverse().join(' + ')}</span><br /></>}
        {view.config.height_coefficients && <><span>Height Calculation:<br />{view.config.height_coefficients.reverse().map((x, i) => !x ? false : `${x} ${i > 0 ? `* x^${i}` : ''}`).filter(x => x).reverse().join(' + ')}</span><br /></>}
        Height Ratio: {view.config.height_ratio}
      </TableCell>
      <TableCell colSpan={2}>
        Capture Video: {view.capture_video ? "Yes" : "No"}<br/>
        Capture Raw Video: {view.capture_raw_video ? "Yes" : "No"}<br/>
        Capture Training Images: {view.capture_training_images ? "Yes" : "No"}
      </TableCell>
    </TableRow>}
    </TableBody>
  );
};

const ViewConfigs: React.FC<ViewConfigsProps> = ({ siteId, view_type }) => {
  const { isLoading, isError, data } = useQuery<ViewConfig[], Error>(['view-config', siteId, view_type], () => getViewConfigBySite(siteId, view_type));

  const title = view_type === 'live' ? 'Views' : 'Recordings';

  const queryClient = useQueryClient();
  const editViewConfig = useMutation(updateViewConfig, {
    onSuccess: () => {
      queryClient.invalidateQueries(['view-config', siteId, view_type]);
    }
  });

	return (
    <Card>
      <CardHeader
        title={title}
      />
      <CardContent>
        {isLoading ?
           <CircularProgress /> :
           (isError ?
            <Typography>There has been an error loading your data.</Typography> :
              ((!data || data.length === 0) ?
                <Typography>{'live' === view_type ? 'There are no view configs.' : 'There are no recordings.'}</Typography> :
                <Table aria-label="View Configs">
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Name</TableCell>
                      <TableCell>Site</TableCell>
                      <TableCell>Camera</TableCell>
                      <TableCell>Lane</TableCell>
                      <TableCell>Processor</TableCell>
                      <TableCell>Model</TableCell>
                      <TableCell>Process</TableCell>
                      {'live' === view_type && <TableCell>&nbsp;</TableCell>}
                    </TableRow>
                  </TableHead>
                  {data.sort((a, b) => a.id - b.id).map((view) => <ViewConfigRow key={view.id} siteId={siteId} view={view} editViewConfig={editViewConfig} />)}
                </Table>))}
      </CardContent>
    </Card>
  );
};

export default ViewConfigs;
